<template>
  <div class="xinwen">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div class="main">
      <div class="search">
        <el-input v-model="news" placeholder="请输入内容"></el-input>
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/xinwenFdj.png"
          alt=""
          class="fangda"
          @click="getNews"
        />
      </div>
      <div v-if="xwList.length > 0 && bigNew != ''">
        <div class="bigXinwen" v-if="bigNew != '' && pageNum == 1">
          <img :src="bigNew.remark1" alt="" @click="toDetail(bigNew.id)" />
          <div class="bigBtm" @click="toDetail(bigNew.id)">
            <div class="leftTitle">{{ bigNew.title }}</div>
            <div class="rightTime">{{ bigNew.createTime }}</div>
          </div>
        </div>

        <div class="xinwenMain">
          <div
            class="xwItem"
            v-for="(item, index) in xwList"
            :key="index"
            @click="toDetail(item.id)"
          >
            <img :src="item.remark2" alt="" />
            <div class="xwBtm">
              <div class="xwBtmTitle">{{ item.title }}</div>
              <div class="wxBtmTime">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            page-size="6"
            @prev-click="prevfun"
            @next-click="nextfun"
            @current-change="currentchange"
            v-if="xwList.length > 0"
          >
          </el-pagination>
        </div>
      </div>

      <div class="newLine">
        <div
          class="newsTitle"
          v-for="(item, index) in xwLineList"
          :key="index"
          @click="toskipLine(item.id)"
        >
          {{ index + 1 }}、
          {{ item.title }}
        </div>

        <div class="block">
          <el-pagination
            layout="prev, pager, next"
            :total="linetotal"
            page-size="6"
            @prev-click="prevfun1"
            @next-click="nextfun1"
            @current-change="currentchange1"
            v-if="xwLineList.length > 0"
          >
          </el-pagination>
        </div>
      </div>
      <div
        class="nullResult"
        v-if="xwList.length <= 0 && bigNew == '' && xwLineList.length <= 0"
      >
        "未查询到结果"
      </div>
    </div>

    <div class="empty"></div>
    <footerBtm></footerBtm>
  </div>
</template>
<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "xinwen",
  components: { headerNav, footerBtm },         
  data() {
    return {
      bannerList: [],
      news: "",
      xwList: [],
      bigNew: [],
      total: "",
      pageNum: 1,
      xwLineList: [],
      linetotal: "",
      pageNum1: 1,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.api.allPage({ name: "新闻中心" }).then((res) => {});
    // banner
    this.api.banner({ remark1: 6 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
    // xinwen
    this.api
      .xinwen({
        text: this.news,
        title: this.news,
        pageNum: 1,
        pageSize: 6,
      })
      .then((res) => {
        if (res.data[0].length > 0) {
          res.data[0][0].remark1 = JSON.parse(res.data[0][0].remark1);
          this.bigNew = res.data[0][0];
        } else {
          this.bigNew = [];
        }
        if (res.data[1].rows.length > 0) {
          for (var i = 0; i < res.data[1].rows.length; i++) {
            res.data[1].rows[i].remark2 = JSON.parse(
              res.data[1].rows[i].remark2
            );
            this.xwList = res.data[1].rows;
            this.total = res.data[1].total;
          }
        } else {
          this.xwList = [];
          this.total = 1;
        }
      });

    this.api
      .xinwen({
        text: this.news,
        title: this.news,
        pageNum: 1,
        pageSize: 10,
      })
      .then((res) => {
        console.log(res.data[2].rows.length);
        if (res.data[2].rows.length > 0) {
          this.xwLineList = res.data[2].rows;
          this.linetotal = res.data[2].total;
        } else {
          this.xwLineList = [];
          this.linetotal = 1;
        }
      });
  },
  methods: {
    toskipLine(e) {
      this.$router.push(`/xwLine?id=${e}`);
    },
    currentchange(value) {
      //页码发生变化会触发，这个用来触发点击页码时触发的。
      this.pageNum = value;
      this.getNewsTwo(value); //调取接口
    },
    prevfun(value) {
      //value拿到的当前的页码，点击上一页触发该函数
      this.pageNum = value;
      this.getNewsTwo(value); //调取接口
    },
    nextfun(value) {
      //value拿到页码，点击下一页触发该函数
      this.pageNum = value;
      this.getNewsTwo(value); //调取接口
    },

    currentchange1(value) {
      //页码发生变化会触发，这个用来触发点击页码时触发的。
      this.pageNum1 = value;
      this.getNewsTwo1(value); //调取接口
    },
    prevfun1(value) {
      //value拿到的当前的页码，点击上一页触发该函数
      this.pageNum1 = value;
      this.getNewsTwo1(value); //调取接口
    },
    nextfun1(value) {
      //value拿到页码，点击下一页触发该函数
      this.pageNum1 = value;
      this.getNewsTwo1(value); //调取接口
    },
    getNewsTwo(num) {
      this.pageNum = num;
      this.api
        .xinwen({
          text: this.news,
          title: this.news,
          pageNum: num,
          pageSize: 6,
        })
        .then((res) => {
          if (res.data[0].length > 0) {
            res.data[0][0].remark1 = JSON.parse(res.data[0][0].remark1);
            this.bigNew = res.data[0][0];
          } else {
            this.bigNew = [];
          }
          if (res.data[1].rows.length > 0) {
            for (var i = 0; i < res.data[1].rows.length; i++) {
              res.data[1].rows[i].remark2 = JSON.parse(
                res.data[1].rows[i].remark2
              );
              this.xwList = res.data[1].rows;
              this.total = res.data[1].total;
            }
          } else {
            this.xwList = [];
            this.total = num;
          }
        });
    },
    getNewsTwo1(num) {
      this.pageNum1 = num;
      this.api
        .xinwen({
          text: this.news,
          title: this.news,
          pageNum: num,
          pageSize: 10,
        })
        .then((res) => {
          if (res.data[2].rows.length > 0) {
            for (var i = 0; i < res.data[2].rows.length; i++) {
              this.xwLineList = res.data[2].rows;
              this.linetotal = res.data[2].total;
            }
          } else {
            this.xwLineList = [];
            this.linetotal = 1;
          }
        });
    },
    getNews(num) {
      this.api
        .xinwen({ text: this.news, title: this.news, pageNum: 1, pageSize: 6 })
        .then((res) => {
          if (res.data[0].length > 0) {
            res.data[0][0].remark1 = JSON.parse(res.data[0][0].remark1);
            this.bigNew = res.data[0][0];
          } else {
            this.bigNew = [];
          }
          if (res.data[1].rows.length > 0) {
            for (var i = 0; i < res.data[1].rows.length; i++) {
              res.data[1].rows[i].remark2 = JSON.parse(
                res.data[1].rows[i].remark2
              );
              this.xwList = res.data[1].rows;
              this.total = res.data[1].total;
            }
          } else {
            this.xwList = [];
            this.total = 1;
          }
          this.pageNum = 1;

          this.api
            .xinwen({
              text: this.news,
              title: this.news,
              pageNum: 1,
              pageSize: 10,
            })
            .then((res) => {
              if (res.data[2].rows.length > 0) {
                for (var i = 0; i < res.data[2].rows.length; i++) {
                  this.xwLineList = res.data[2].rows;
                  this.linetotal = res.data[2].total;
                }
              } else {
                this.xwLineList = [];
                this.linetotal = 1;
              }
              this.pageNum1 = 1;
            });
        });
    },
    toDetail(e) {
      this.$router.push(`/xwDetail?id=${e}`);
    },
  },
};
</script>
<style scoped>
.main {
  width: 1280px;
  margin: 40px auto;
}
.search {
  width: 100%;
  height: 80px;
  margin-bottom: 60px;
  position: relative;
}
.el-input {
  width: 1280px;
  height: 80px;
  color: #975b00;
}
div /deep/.el-input__inner {
  background: #fffde8;
  border: 2px solid #ffe500;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 80px;
  line-height: 80px;
  outline: 0;
  padding: 0 0px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  border-radius: 100px 100px 100px 100px;
  padding-left: 50px;
  font-size: 24px;
}
div /deep/.el-input__inner ::placeholder {
  color: #975b00;
}

.fangda {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 40px;
  top: 20px;
  cursor: pointer;
}

.bigXinwen {
  width: 100%;
  font-size: 0;
}
.bigXinwen img {
  width: 100%;
  height: 490px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
}
.bigBtm {
  width: 90%;
  height: 110px;
  margin: 0 auto;
  background: #ffe500;
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}
.leftTitle {
  width: 1000px;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  line-height: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.rightTime {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  line-height: 110px;
  width: 100%;
  text-align: right;
}

.xinwenMain {
  width: 1280px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 60px;
}
.xwItem {
  width: 400px;
  height: 500px;
  margin-bottom: 40px;
  border-radius: 20px;
  cursor: pointer;
  background: #ffe500;
  margin-right: 40px;
}
.xwItem:nth-of-type(3n) {
  margin-right: 0;
}
.xwItem img {
  width: 100%;
  height: 300px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.xwBtm {
  width: 90%;
  height: 160px;
  margin: 10px auto;
  position: relative;
}
.xwBtmTitle {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wxBtmTime {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  position: absolute;
  bottom: 30px;
  right: 0;
}
.block {
  width: 100%;
  position: relative;
}
.el-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.nullResult {
  text-align: center;
}

.newLine {
  width: 100%;
  margin-top: 60px;
  text-decoration: underline;
}
.newsTitle {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 20px 0;
  cursor: pointer;
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .xwItem {
    height: 450px;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  .main {
    width: 911px;
    margin: 20px auto;
  }
  .search {
    height: 57px;
  }
  .el-input {
    width: 910px;
    height: 57px;
  }
  div /deep/ .el-input__inner {
    height: 57px;
    font-size: 17px;
  }
  .fangda {
    width: 28px;
    height: 28px;
    top: 14px;
  }
  .bigXinwen img {
    height: 350px;
  }
  .bigBtm {
    height: 78px;
  }
  .leftTitle {
    font-size: 17px;
    line-height: 78px;
    font-weight: bold;
  }
  .rightTime {
    font-size: 17px;
    line-height: 78px;
    font-weight: bold;
  }
  .xinwenMain {
    width: 100%;
  }
  .xwItem {
    width: 285px;
    height: 344px;
    border-radius: 14px;
    margin-right: 28px;
    margin-bottom: 28px;
  }
  .xwItem img {
    height: 213px;
  }
  .xwBtm {
    height: 131px;
  }
  .xwBtmTitle {
    font-size: 17px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  .main {
    width: 853px;
    margin: 20px auto;
  }
  .search {
    height: 57px;
    margin-bottom: 40px;
  }
  .el-input {
    width: 853px;
    height: 57px;
  }
  div /deep/ .el-input__inner {
    height: 57px;
    font-size: 16px;
  }
  .fangda {
    width: 28px;
    height: 28px;
    top: 14px;
  }
  .bigXinwen img {
    height: 327px;
  }
  .bigBtm {
    height: 73px;
  }
  .leftTitle {
    font-size: 16px;
    line-height: 73px;
    font-weight: bold;
  }
  .rightTime {
    font-size: 16px;
    line-height: 73px;
    font-weight: bold;
  }
  .xinwenMain {
    width: 100%;
  }
  .xwItem {
    width: 267px;
    height: 323px;
    border-radius: 14px;
    margin-right: 26px;
    margin-bottom: 28px;
  }
  .xwItem img {
    height: 200px;
  }
  .xwBtm {
    height: 123px;
  }
  .xwBtmTitle {
    font-size: 17px;
  }
}

@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .main {
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    margin-top: 0;
  }
  .search {
    height: 40px;
    margin-bottom: 27px;
  }
  .el-input {
    width: 100%;
    height: 40px;
  }
  div /deep/ .el-input__inner {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding-left: 20px;
  }
  .fangda {
    width: 24px;
    height: 24px;
    top: 8px;
    right: 13px;
  }
  .bigXinwen {
    width: 100%;
  }
  .bigXinwen img {
    height: 150px;
  }
  .bigBtm {
    width: 100%;
    height: 50px;
    flex-wrap: wrap;
    padding-top: 8px;
    box-sizing: border-box;
  }
  .leftTitle {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
  }
  .rightTime {
    font-size: 12px;
    line-height: 12px;
  }
  .xinwenMain {
    width: 100%;
    justify-content: space-between;
  }
  .xwItem {
    /* width: 163px; */
    height: 198px;
    width: 46%;
    margin-right: 16px;
  }
  .xwItem:nth-of-type(3n) {
    margin-right: 16px;
  }
  .xwItem:nth-of-type(2n) {
    margin-right: 0;
  }
  .xwItem img {
    width: 100%;
    height: 128px;
  }
  .xwBtm {
    height: 70px;
  }
  .xwBtmTitle {
    font-size: 12px;
    line-height: 12px;
  }
  .wxBtmTime {
    font-size: 10px;
    line-height: 10px;
  }
}
</style>
